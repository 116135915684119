@import '../../../sharedStyles/shared.module.scss';

.ProjectCardWrapper {
  cursor: pointer;
  transition: 0.1s ease-in;

  &:hover {
    transform: scale(1.03);
  }
}

.ProjectCardContainer {
  box-shadow: $box-shadow;
  background-color: $tertiary-color !important;
  color: $secondary-color;
  border: 0 !important;
}

.ProjectImg {
  object-fit: cover;
  height: 180px;
  border-radius: 0.25rem 0.25rem 0 0;
}

.ProgressBar {
  width: 100%;
  // border-radius: 0 !important;

  div {
    background: $lime !important;
  }
}

.ProjectDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  opacity: 0.9;
  height: 48px;
}

.FeaturedProjectExtraInfo {
  opacity: 0.85;
  font-size: 0.75rem;
}

.FeaturedProjectCreatorName {
  font-style: oblique;
  &:hover {
    text-decoration: underline;
  }
}

.MoneyRaised {
  font-size: 0.875rem;
}
