@import '../../../sharedStyles/shared.module.scss';

.Button {
    border: 1px solid $primary-color;
    color: $primary-color;
    background-color: transparent;
    padding: 5px 30px;
    border-radius: 0 0 10px 10px;
    opacity: 0;
    border-top: 0;
    // transition: 0.3s ease-in;
}

@media screen and(max-width: 375px) {
    .Button {
        font-size: 15px;
        padding: 8px 22px;
    }
}

@media screen and(max-width: 320px) {
    .Button {
        font-size: 14px;
        padding: 8px 22px;
    }
}
