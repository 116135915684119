@import '../../sharedStyles/shared.module.scss';

.ProjectContainer {
  background: $tertiary-color;
  padding: 3em 0;
  border-radius: 2rem;
  color: $secondary-color;
  box-shadow: $box-shadow;
}

.Image {
  object-fit: cover;
  width: 100%;
  height: 420px;
}

.TagContainer {
  span {
    margin-right: 10px;
    font-weight: 500;
  }
}

.ProgressBar {
  width: 100%;

  div {
    background: $lime !important;
  }
}

.DateText {
  font-size: 0.875rem;
}

.Button {
  outline: none;
  border: 0;
  width: 100%;
  background: $primary-color;
  color: $secondary-color;
  border-radius: 0.25rem;

  &:hover {
    background: $muted-color;
  }
}

.Names {
  font-style: italic;
}

.AuthorDescription {
  font-style: italic;
}

.Link {
  color: $primary-color;
  &:hover {
    color: $muted-color;
  }
}

.InvestModalWrapper {
  margin: auto;
  max-width: 450px;
  border: 0 !important;
  border-radius: 1rem !important;
}

.InvestModal {
  background: $tertiary-color;
  color: $secondary-color;
  border-radius: 1rem;
}

.ModalHeader {
  button {
    filter: invert(1);
  }
}

.PerkDescription {
  font-style: italic;
}

.Perk {
  padding: 5px 10px;
  border: 1px solid $primary-color;
  border-radius: 0.5rem;
  font-size: 0.9rem;
}
