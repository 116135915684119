@import '../../../../sharedStyles/shared.module.scss';

.ConnectButton {
    border: none;
    font-size: large;
    font-weight: bold;
    padding: 6px 40px;
    background-color: $primary-color;
    color: $secondary-color;
    border-radius: 10px
}

.ConnectButtonSmall {
    @extend .ConnectButton;
}

@media screen and (max-width: 992px) {
    .ConnectButton {
        font-size: 1.2rem;
        padding: 6px 30px;
    }

    .ConnectButtonSmall {
        font-size: 10px;
        padding: 6px;
    }
}