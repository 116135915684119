@import '../../sharedStyles/shared.module.scss';

.Form {
    max-width: 480px;
    margin: auto;

    .FormLabel {
        margin: 0;
    }

    svg {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        left: 6px;
    }

    input,
    input:focus {
        outline: none;
        color: $text-color;
        background: $secondary-color;
        padding-left: 30px;
    }

    input:focus {
        box-shadow: 0 0 0 0.25rem rgb(157 177 108 / 22%);
    }

    button {
        cursor: pointer;
        background-color: $primary-color;
        color: $secondary-color;
        border: 0;

        &:focus,
        &:focus,
        &:active:focus {
            outline: none;
            box-shadow: none;
            background-color: $primary-color;
            color: $secondary-color;
        }

        &:hover {
            background-color: $muted-color;
            color: $secondary-color;
        }
    }
}

.FormWrapper {
    border-radius: 20px;
    background: rgb(53, 77, 100);
    background: linear-gradient(145deg, rgba(19, 64, 115, 1) 0%, #263352 50%, rgba(27, 49, 104, 1) 100%);
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.44);
    -webkit-box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.44);

    h2 {
        color: $secondary-color;
    }
}
