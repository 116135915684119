@import '../../../sharedStyles/shared.module.scss';

.Button {
    color: $primary-color !important;
    border-color: $primary-color !important;

    &:hover {
        background: $primary-color !important;
        color: $secondary-color !important;
    }
}

.Header {
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;

    a {
        border: 1px solid $primary-color;
        color: $primary-color !important;
        padding: 5px 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
        }
    }
}

.CloseTitle {
    height: 56px;
    background-color: $primary-color;
}

.Body {
    hr {
        color: $primary-color;
        opacity: 8%;
    }

    .Account {
        height: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        h1 {
            color: $primary-color;

            font-weight: 700;
            font-size: 23.2648pt;
            line-height: 16px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        h3 {
            color: $muted-color;
            font-size: 16.28535pt;
            line-height: 13px;
        }
    }

    .BodyNav {
        height: calc(80% - 2rem - 1px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .Nav {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;

            * {
                font-weight: 500;
                font-size: 20px;
                line-height: 16px;
            }

            svg:not([name='vivid']) {
                color: black;
                opacity: 0.6;
            }

            a,
            p {
                cursor: pointer;
                margin-bottom: 0;
                margin-left: 30px;
                color: $primary-color;
                text-decoration: none;
            }

            .Logout {
                color: $primary-color;
            }
        }
    }
}
