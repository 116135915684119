@import '../../sharedStyles/shared.module.scss';

.MainFeaturedProject {
  border-right: 1px solid $text-color;
}

.FeaturedProject {
  cursor: pointer;
  transition: 0.2s ease-out;
  background: $purple;
  color: $secondary-color;
  border-radius: 0.5rem;
  box-shadow: $box-shadow;

  &:hover {
    transform: scale(1.03);

    .FeaturedProjectTitle {
      text-decoration: underline;
    }
  }
}

.FeaturedProjectImg {
  max-height: 300px;
  border-radius: 0.5rem 0.5rem 0 0;
}

.FeaturedProjectDescription {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-style: italic;
}

.FeaturedProjectExtraInfo {
  opacity: 0.85;
  font-size: 0.75rem;
}

.FeaturedProjectCreatorName {
  font-style: oblique;
  &:hover {
    text-decoration: underline;
  }
}

.ProgressBar {
  width: 100%;
  // border-radius: 0 !important;

  div {
    background: $lime !important;
  }
}

.FundedText{
  font-size: 1.25rem;
  font-style: italic;
  // color: $lime;
}
