@import '../../../sharedStyles/shared.module.scss';

.ListCard {
  cursor: pointer;
  border-bottom: 1px solid $text-color;
  transition: 0.1s ease-in;
  color: $secondary-color;
  
  .ListCardContainer {
    border-radius: 0.5rem;
    box-shadow: $box-shadow;
    background: $tertiary-color;
  }

  img {
    height: 90px;
    object-fit: cover;
    border-radius: 0.25rem 0 0 0;
  }

  &:hover {
    transform: scale(1.03);

    h6 {
      text-decoration: underline;
    }
  }
}

.ProgressBar {
  width: 100%;
  // border-radius: 0 0 0 0.25rem !important;

  div {
    background: $lime !important;
  }
}

.FeaturedProjectExtraInfo {
  opacity: 0.85;
  font-size: 0.75rem;
}

.FeaturedProjectCreatorName {
  font-style: oblique;
  &:hover {
    text-decoration: underline;
  }
}

.FundedText {
  font-size: 0.875rem;
  // margin-bottom: 0.5rem;
}
