$blue: #134073;
$purple: #1B3168;
$black: #16181d;
$white: rgb(241, 240, 235);
$lime: #02D78F;
$box-shadow: 1px 1px 4px 1px #202121;;

$primary-color: #e73173;
$muted-color: #d42968;
$secondary-color: $white;
$tertiary-color: $purple;
$text-color: $black;

body {
    color: $primary-color;
}
