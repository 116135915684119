@import '../../sharedStyles/shared.module.scss';

.Form {
    form {
        width: 100%;
    }

    label {
        color: $secondary-color;
    }

    input,
    input:focus {
        box-shadow: none;
        outline: none;
        border-color: $tertiary-color;
    }

    button {
        background-color: $primary-color;
        color: $secondary-color;
        border: 0;
        font-weight: 700;

        &:focus,
        &:focus,
        &:active:focus {
            outline: none;
            box-shadow: none;
            background-color: $primary-color;
            color: $secondary-color;
        }

        &:hover {
            background-color: $muted-color;
            color: $secondary-color;
        }
    }

    h4 {
        color: $secondary-color;
        text-align: center;
        text-decoration: underline;
    }

    .Perk {
        border: 1px solid $primary-color;
        border-radius: 10px;

        > div {
            color: $secondary-color;
            background-color: transparent;

            span {
                font-weight: bold;
            }
        }

        button {
            border: none;
            border-radius: 7px;
        }
    }
}

.FormWrapper {
    border-radius: 20px;
    background: rgb(53, 77, 100);
    background: linear-gradient(145deg, rgba(19, 64, 115, 1) 0%, #263352 50%, rgba(27, 49, 104, 1) 100%);
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.44);
    -webkit-box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.44);

    h2 {
        color: $secondary-color;
    }
}

.InvestModalWrapper {
    margin: auto;
    max-width: 450px;
    border: 0 !important;
    border-radius: 1rem !important;
}

.InvestModal {
    background: $tertiary-color;
    color: $secondary-color;
    border-radius: 1rem;
}

.ModalHeader {
    button {
        filter: invert(1);
    }
}
