@import '../../sharedStyles/shared.module.scss';

.Page {
    height: 100%;

    .Navigation {
        background-color: $white;
        color: $text-color;
        text-align: center;
        padding: 10px;
        box-shadow: 1px 1px 12px 2px #202121;
        position: fixed;
        height: 100vh;

        .Logo {
            &:hover {
                background: transparent;
            }
        }

        .ConnectedBtn {
            border: 1px solid $primary-color;
            color: $primary-color !important;
            padding: 10px 10px;
            border-radius: 10px;
            display: inline-block;

            svg {
                margin-right: 5px;
            }
        }

        .ConnectedMenu {
            &:hover {
                button {
                    opacity: 1;
                }

                .ConnectedBtn {
                    border-radius: 10px 10px 0 0;
                }
            }
        }

        h2 {
            color: $primary-color;
        }

        a {
            text-decoration: none;
            color: $black;
            display: flex;
            justify-content: left;
            align-items: center;
            font-size: large;
            padding: 10px 0 10px 20%;

            &:hover {
                background: #e2dddd;
            }

            span {
                margin-left: 10px;
            }
        }

        .Connect {
            background-color: $primary-color;
            width: fit-content;
            padding: 10px 15px;
            margin: 20px auto;
            border-radius: 15px;
            color: $secondary-color;

            &:hover {
                background: $primary-color;
            }
        }
    }

    .Layout {
        margin: auto;
        display: flex;
        // justify-content: center;
        padding-top: 50px;
        // align-items: flex-start;
        height: calc(100% - 60px);

        // height: 100%;
        width: 100%;
        // display: flex;
        flex-direction: column;
        align-items: center;
    }
}
