@import '../../sharedStyles/shared.module.scss';

.PlatformSummary {
  border-radius: 1rem;
  padding: 2rem 0;
  background: linear-gradient(145deg, rgba(19, 64, 115, 1) 0%, rgba(27, 49, 104, 1) 100%);
  box-shadow: $box-shadow;
  color: $secondary-color;

  transition: 0.1s ease-in;

  &:hover {
    transform: scale(1.015);
  }
}

.PlatformSummaryCard {
  &:not(:last-child) {
    border-right: 1px solid $secondary-color;
  }
}
