@import '../../sharedStyles/shared.module.scss';

.Card {
    border-radius: 10px !important;
    border-color: $text-color !important;
    background-color: $tertiary-color !important;
    height: 100%;
    min-width: 200px !important;
}

.CardBody {
    background-color: $tertiary-color;
    border-radius: 10px;
    justify-content: flex-end;
    display: flex !important;
    flex-direction: column !important;
}

.CardTitle {
    color: $primary-color;
    font-weight: bold;
    font-size: large !important;
}

;

.CardText {
    color: $text-color;
    font-size: normal;
}

;

.CardImage {
    border-radius: 20px !important;
    margin: 10px;
}

;

.Zoom:hover {
    transform: scale(1.1);
    transition-duration: 0.2s;
    border-color: $primary-color !important;
}

.Badge {
    background-color: $primary-color !important;
    color: $tertiary-color !important;
    margin-right: 5px;
}

.badges {
    display: flex;
}

.Warning {
    color: $primary-color;
}